<template>
  <div class="p-1">
    <b-overlay
      :show="isUploading"
      rounded="sm"
    >
      <b-avatar
        :src="imagePath"
        :size="size"
        variant="primary"
      />
      <div>
        <b-button
          v-if="isEditable"
          variant="gradient-danger"
          class="btn-icon rounded-circle"
          @click="onPickFile"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          accept="image/*"
          @change="onFilePicked"
        >
      </div>
    </b-overlay>
  </div>
</template>
<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ImageUploader',
  props: {
    size: {
      type: String,
      default: '250px',
    },
    isShowNotification: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isUploading: false,
      uploadedImage: null,
    }
  },
  computed: {
    imagePath() {
      return this.image || this.uploadedImage
    },
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      const { files } = event.target
      const reader = new FileReader()
      const formData = new FormData()
      formData.append('image', files[0])
      reader.readAsDataURL(files[0])
      reader.onload = async () => {
        this.isUploading = true
        const response = await axios.post('/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response.status === 422) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.errors[0],
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          this.isUploading = false
          return
        }
        this.uploadedImage = response.data.image_path
        this.isUploading = false
        this.$emit('set-image', response.data)
      }
    },
  },
}
</script>
