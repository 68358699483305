<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="bg-primary text-white p-2 mb-2">
        <feather-icon :icon="icon" style="width: 24px; height: 24px" />
        <span class="ml-2 font-weight-bolder">{{ message }}</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'AnnoucementBanner',
    props: {
      icon: {
        required: true,
        type: String
      },
      message: {
        required: true,
        type: String
      }
    }
  }
</script>