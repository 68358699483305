var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"text-center mb-2",attrs:{"lg":"4"}},[_c('validation-provider',{attrs:{"vid":"form_data.client.pic","name":"logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUploader',{attrs:{"is-editable":_vm.isEditable,"image":_vm.form.form_data.image_path},on:{"set-image":_vm.handleSetImage}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"lg":"8"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('type_of_market')}},[_c('MySelectTypeOfMarket',{attrs:{"market":_vm.form.form_data.client.type,"vid-name":'form_data.client.type',"is-editable":_vm.isEditable},on:{"set-selected-market":_vm.handleSetSelectedMarket}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('phone_number')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.phone","name":"Phone Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_phone_number'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.phone),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "phone", $$v)},expression:"form.form_data.address.phone"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('main_name')}},[_c('validation-provider',{attrs:{"vid":"form_data.client.name","name":"Main Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_main_name'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.client.name),callback:function ($$v) {_vm.$set(_vm.form.form_data.client, "name", $$v)},expression:"form.form_data.client.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('outlet_branch_name')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.name","name":"Outlet Branch Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_outlet_branch_name'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.name),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "name", $$v)},expression:"form.form_data.address.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('unit_number')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.unit","name":"Unit Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_unit_number'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.unit),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "unit", $$v)},expression:"form.form_data.address.unit"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('address_line_1')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.address1","name":"Address Line 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_address_line_1'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.address1),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "address1", $$v)},expression:"form.form_data.address.address1"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('address_line_2')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.address2","name":"Address Line 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_address_line_2'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.address2),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "address2", $$v)},expression:"form.form_data.address.address2"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('enter_address_line_3') + _vm.$t('optional')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.address3","name":"Address Line 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_address_line_3'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.address3),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "address3", $$v)},expression:"form.form_data.address.address3"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('postcode')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.postcode","name":"Postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_postcode'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.postcode),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "postcode", $$v)},expression:"form.form_data.address.postcode"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('area')}},[_c('validation-provider',{attrs:{"vid":"form_data.address.area","name":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter_area'),"readonly":!_vm.isEditable},model:{value:(_vm.form.form_data.address.area),callback:function ($$v) {_vm.$set(_vm.form.form_data.address, "area", $$v)},expression:"form.form_data.address.area"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('state')}},[_c('MySelectStates',{attrs:{"state":_vm.form.form_data.address.state,"vid-name":'form_data.address.state',"is-editable":_vm.isEditable},on:{"set-selected-state":_vm.handleSetSelectedState}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('country')}},[_c('MySelectCountries',{attrs:{"country":_vm.form.form_data.address.country,"vid-name":'form_data.address.country',"is-editable":_vm.isEditable},on:{"set-selected-country":_vm.handleSetSelectedCountry}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }