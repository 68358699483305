<template>
  <b-overlay>
    <div>
      <div class="d-flex justify-content-center">
        <b-button
          class="my-5 rounded box-shadow-1 d-flex flex-column justify-content-between align-items-center package-container w-50 mr-2"
          variant="outline-primary"
          @click="payCash()"
        >
          <img
            :src="require('@/assets/images/logo/cash.png')"
            class="img-fluid w-25 mt-2"
          />
          <h3 class="my-2">Logpoint</h3>
        </b-button>
        <b-button
          class="my-5 rounded box-shadow-1 d-flex flex-column justify-content-between align-items-center package-container w-50 ml-2"
          variant="outline-primary"
          @click="payEwallet()"
        >
          <img
            :src="require('@/assets/images/logo/visa.png')"
            class="img-fluid w-50 mt-2"
          />
          <h3 class="my-2">Debit / Credit Card</h3>
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>
  
<script>
export default {
  data() {
    return { mop: '' }
  },
  methods: {
    payCash() {
      this.$emit('payment_method', 'LOGPOINT')
    },
    payEwallet() {
      this.$emit('payment_method', 'SENANGPAY')
    }
  }
}
</script>
  
<style scoped>
</style>
  