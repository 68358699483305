<template>
  <b-modal
    id="inquiry-modal"
    ref="inquiry-modal"
    v-model="show"
    :title="title"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <b-container
      fluid
      class="py-3"
    >
      <ValidationObserver
        ref="editInquiryForm"
        v-slot="{ passes }"
      >
        <form @submit.prevent="passes(handleEditInquiry)">
          <b-row>
            <b-col md="12">
              <div v-if="inquiryType === 1">
                <AddressEntryInquiry
                  :form="formData"
                  :is-editable="isEditable"
                />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isEditable">
            <b-col
              cols="12"
            >
              <b-button
                variant="primary"
                class="float-right mt-3"
                type="submit"
                :disabled="isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  class="mr-50 p-0"
                  small
                />
                <feather-icon
                  v-else
                  icon="SaveIcon"
                  class="mr-50"
                />
                Edit Inquiry
              </b-button>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-container>
  </b-modal>
</template>

<script>
import AddressEntryInquiry from '@/components/Inquiries/AddressEntryInquiry.vue'
import { ValidationObserver } from 'vee-validate'
import axios from '@axios'

export default {
  name: 'EditInquiryModal',
  components: {
    AddressEntryInquiry,
    ValidationObserver,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Inquiry Details',
    },
    formData: {
      type: Object,
      default: () => {},
    },
    inquiryType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
    }
  },
  computed: {
    isEditable() {
      return this.formData && this.formData.status.text === 'Please Re-Edit'
    },
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.$emit('close-modal')
    },
    async handleEditInquiry() {
      try {
        this.isLoading = true
        const response = await axios.patch(`/inquiries/${this.formData.id}`, this.formData)
        if (response.status === 422) {
          this.$refs.editInquiryForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        if (response.status === 200) {
          this.$swal({
            icon: 'success',
            title: 'Inquiry Edited',
            text: 'Successfully Edited The Inquiry',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.handleHiddenModal()
            this.$emit('refetch-inquiries')
          })
        }
        this.isLoading = false
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>
