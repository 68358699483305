<template>
  <div>
    <b-alert
      v-if="!orders.length"
      variant="info"
      show
    >
      <div class="alert-body">
        {{ $t('no_orders_found') }}
      </div>
    </b-alert>
    <b-row
      v-for="order in orders"
      :key="order.id"
      class="mb-2"
    >
      <b-col sm="5">
        <img
          width="100%"
          :src="`${this.$s3URL}uploads/user/`+order.pic"
          alt="client"
        ></b-col>
      <b-col>
        <h3 class="mb-0">
          {{ $t('src.components.schedulelist.box', { '1': order.qty }) }}
        </h3>{{ $t('src.components.schedulelist.pickup', { '1': $moment(order.delivery_date).format('DD-MM-YYYY hh:mm A') }) }}</b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
