<template>
  <div>
    <b-row>
      <b-col
        lg="4"
        class="text-center mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          vid="form_data.client.pic"
          name="logo"
        >
          <ImageUploader
            :is-editable="isEditable"
            :image="form.form_data.image_path"
            @set-image="handleSetImage"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-col>
      <b-col
        lg="8"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('type_of_market')"
            >
              <MySelectTypeOfMarket
                :market="form.form_data.client.type"
                :vid-name="'form_data.client.type'"
                :is-editable="isEditable"
                @set-selected-market="handleSetSelectedMarket"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('phone_number')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.phone"
                name="Phone Number"
              >
                <b-form-input
                  v-model="form.form_data.address.phone"
                  :placeholder="$t('enter_phone_number')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('main_name')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.client.name"
                name="Main Name"
              >
                <b-form-input
                  v-model="form.form_data.client.name"
                  :placeholder="$t('enter_main_name')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('outlet_branch_name')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.name"
                name="Outlet Branch Name"
              >
                <b-form-input
                  v-model="form.form_data.address.name"
                  :placeholder="$t('enter_outlet_branch_name')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              :label="$t('unit_number')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.unit"
                name="Unit Number"
              >
                <b-form-input
                  v-model="form.form_data.address.unit"
                  :placeholder="$t('enter_unit_number')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              :label="$t('address_line_1')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.address1"
                name="Address Line 1"
              >
                <b-form-input
                  v-model="form.form_data.address.address1"
                  :placeholder="$t('enter_address_line_1')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('address_line_2')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.address2"
                name="Address Line 2"
              >
                <b-form-input
                  v-model="form.form_data.address.address2"
                  :placeholder="$t('enter_address_line_2')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('enter_address_line_3') + $t('optional')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.address3"
                name="Address Line 3"
              >
                <b-form-input
                  v-model="form.form_data.address.address3"
                  :placeholder="$t('enter_address_line_3')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('postcode')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.postcode"
                name="Postcode"
              >
                <b-form-input
                  v-model="form.form_data.address.postcode"
                  :placeholder="$t('enter_postcode')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('area')"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.area"
                name="Area"
              >
                <b-form-input
                  v-model="form.form_data.address.area"
                  :placeholder="$t('enter_area')"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('state')"
            >
              <MySelectStates
                :state="form.form_data.address.state"
                :vid-name="'form_data.address.state'"
                :is-editable="isEditable"
                @set-selected-state="handleSetSelectedState"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('country')"
            >
              <MySelectCountries
                :country="form.form_data.address.country"
                :vid-name="'form_data.address.country'"
                :is-editable="isEditable"
                @set-selected-country="handleSetSelectedCountry"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import { ValidationProvider } from 'vee-validate'
import MySelectTypeOfMarket from '@/components/MySelectTypeOfMarket.vue'
import MySelectStates from '@/components/MySelectStates.vue'
import MySelectCountries from '@/components/MySelectCountries.vue'

export default {
  name: 'AddressEntryInquiry',
  components: {
    ImageUploader,
    MySelectTypeOfMarket,
    MySelectStates,
    MySelectCountries,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSetImage(data) {
      this.form.form_data.client.pic = data.file_name
      this.form.form_data.image_path = data.image_path
    },
    async handleSubmitInquiry() {
      this.$emit('submit-inquiry')
    },
    handleSetSelectedMarket(value) {
      this.form.form_data.client.type = value
    },
    handleSetSelectedCountry(value) {
      this.form.form_data.address.country = value
    },
    handleSetSelectedState(value) {
      this.form.form_data.address.state = value
    },
    handleSetSelectedReview() {
      this.form.status = this.selectedReviewAction
    },
  },
}
</script>
